import {format2Dates} from './date';
import moment         from 'moment';

export const momentDates = data => Array.isArray(data) ? data.map(momentDates) : {
    ...data,
    dateDebut: moment(data.dateDebut.date),
    dateFin: moment(data.dateFin.date)
};

export const getBadgeColorStatut = demande => demande.valide === true ? 'success' : demande.valide === false ? 'danger' : 'info';

export const getTextStatut = demande => demande.valide === true ? 'Validé' : demande.valide === false ? 'Refusé' : 'En attente de validation';

export const formatDates = demande => format2Dates(demande.dateDebut, demande.dateFin);

export default {momentDates, getBadgeColorStatut, getTextStatut, formatDates};