<template>
    <card-table-search title="Liste des reservations"
                       :items="reservations"
                       :fields="fields"
                       :actions="actions"
                       :custom-rendered="['utilisateur', 'valide']">
        <template #utilisateur="slotProps">
            <user-name :user="slotProps.row.item.utilisateur"/>
        </template>
        <template #valide="slotProps">
            <b-badge :variant="getBadgeColorStatut(slotProps.row.item)">
                {{ getTextStatut(slotProps.row.item) }}
            </b-badge>
        </template>
    </card-table-search>
</template>

<script>
    const CardTableSearch        = () => import('@/components/CardTableSearch');
    const UserName               = () => import('@/components/UserName');
    const CommentValidationModal = () => import('@/components/modals/CommentValidationModal');

    import {textUsername}       from '@/util/text';
    import {apiPath}            from '@/util/http';
    import alert                from '@/util/alert';
    import demande              from '@/util/demande';
    import {updateBadgesBureau} from '@/util/badge';
    import reservationsMixin    from '@/mixin/reservationsMixin';

    export default {
        name: "AdminReservations",
        components: {CardTableSearch, UserName},
        mixins: [reservationsMixin],
        data() {
            return {
                reservations: [],
                fields: [
                    {
                        key: 'utilisateur',
                        label: 'Utilisateur',
                        sortable: true,
                        formatter: textUsername
                    },
                    {
                        key: 'dateDebut',
                        label: 'Dates',
                        sortable: true,
                        formatter: (value, key, item) => demande.formatDates(item)
                    },
                    {
                        key: 'valide',
                        label: 'Statut',
                        sortable: true,
                        formatter: (value, key, item) => demande.getTextStatut(item)
                    },
                    {
                        key: 'actions',
                        label: 'Actions'
                    }
                ],
                actions: [
                    {
                        key: 'action_accept',
                        color: () => 'success',
                        icon: 'check',
                        tooltip: 'Accepter',
                        display: ({item}) => item.valide === null,
                        handler: ({item}) => this.confirmReservation(item, true)
                    },
                    {
                        key: 'action_refuse',
                        color: () => 'danger',
                        icon: 'times',
                        tooltip: 'Refuser',
                        display: ({item}) => item.valide === null,
                        handler: ({item}) => this.confirmReservation(item, false)
                    },
                    {
                        key: 'action_display',
                        color: () => 'secondary',
                        icon: 'eye',
                        tooltip: 'Détail',
                        handler: ({item}) => this.displayReservationModal(item)
                    },
                    {
                        key: 'action_edit',
                        color: () => 'info',
                        icon: 'pen',
                        tooltip: 'Éditer',
                        handler: ({item}) => this.editReservationModal(item)
                    },
                    {
                        key: 'action_delete',
                        color: () => 'danger',
                        icon: 'trash',
                        tooltip: 'Supprimer',
                        handler: ({item}) => this.deleteReservation(item)
                    }
                ]
            }
        },
        methods: {
            ...demande,
            loadData() {
                alert.loading()
                    .then(() => this.axios.get(apiPath('list_all_reservations_admin', {limit: 100})))
                    .then(response => this.reservations = demande.momentDates(response.data))
                    .catch(() => this.$toaster.error('Impossible de récupérer la liste des réservations'))
                    .finally(alert.stopLoading);
            },
            confirmReservation(reservation, valid) {
                this.$store.dispatch('modal/create', {
                    component: CommentValidationModal,
                    props: {
                        title: (valid ? 'Validation' : 'Refus') + " de l'reservation",
                        commentaireUtilisateur: reservation.commentaireUtilisateur,
                        callback: (commentaire, notifyDiscord) => this.axios
                            .post(
                                apiPath('confirm_reservation_admin', {reservation: reservation.id}),
                                {valid: valid, commentaire: commentaire, notify_discord: notifyDiscord}
                            )
                            .then(() => {
                                this.$toaster.success('Réservation ' + (valid ? 'validée' : 'refusée') + ' avec succès');
                                this.loadData();
                                updateBadgesBureau();
                            })
                            .catch(() => this.$toaster.error("Impossible de modifier la réservation"))
                    }
                })
            }
        },
        mounted() {
            this.loadData();
        }
    }
</script>
