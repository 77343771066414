const ModalAlert   = () => import("@/components/modals/ModalAlert");
const ModalConfirm = () => import("@/components/modals/ModalConfirm");

import store from '@/store';

export const modal = (message, type) => {
    let props = {message: message};
    if (type) {
        props.type = type;
    }
    store.dispatch('modal/create', {component: ModalAlert, props: props});
};

export const loading = () => store.dispatch('modal/triggerAlert');

export const stopLoading = () => store.dispatch('modal/stopAlert');

export const confirm = (message, callback) => {
    store.dispatch('modal/create', {component: ModalConfirm, props: {message, callback}});
};

export default {modal, loading, stopLoading, confirm};