import moment from "moment";

export const makeMoment = value => moment.isMoment(value) ? value : moment(String(value));

export const formatDate = (value = moment(), format = 'DD/MM/YYYY') => makeMoment(value).format(format);

export const formatDateTime = (value = moment(), format = '[Le] DD/MM/YYYY [à] H[h]mm') => formatDate(value, format);

export const format2Dates = (debut, fin, hours = true) => {
    let sameDates = () => 'Le ' + formatDate(debut) +
                          (hours ? ' de ' + formatDate(debut, 'H[h]mm') + ' à ' + formatDate(fin, 'H[h]mm') : '');

    let differentDates = () => 'Du ' + formatDate(debut, hours ? 'DD/MM/YYYY [à] H[h]mm' : 'DD/MM/YYYY')
                               + ' au ' + formatDate(fin, hours ? 'DD/MM/YYYY [à] H[h]mm' : 'DD/MM/YYYY');

    return formatDate(debut) === formatDate(fin) ? sameDates() : differentDates();
};

export default {formatDate, formatDateTime, format2Dates};