const DisplayReservationModal = () => import('@/components/modals/reservation/DisplayReservationModal');
const EditReservationModal    = () => import('@/components/modals/reservation/EditReservationModal');
const ConfirmModal            = () => import('@/components/modals/ConfirmModal');

import {apiPath} from '@/util/http';

export default {
    methods: {
        displayReservationModal(reservation) {
            this.$store.dispatch('modal/create', {
                component: DisplayReservationModal,
                props: {reservation: reservation}
            });
        },
        editReservationModal(reservation) {
            this.$store.dispatch('modal/create', {
                component: EditReservationModal,
                props: {reservation: reservation, callback: this.loadData}
            });
        },
        deleteReservation(reservation) {
            this.$store.dispatch('modal/create', {
                component: ConfirmModal,
                props: {
                    title: 'Supprimer cette réservation ?',
                    callback: () => this.axios
                        .delete(apiPath('delete_reservation', {reservation: reservation.id}))
                        .then(() => {
                            this.$toaster.success('Reservation supprimée avec succès');
                            this.loadData();
                        })
                        .catch(() => this.$toaster.error('Impossible de supprimer la reservation'))
                }
            });
        }
    }
};