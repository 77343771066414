import axios      from 'axios'
import store      from '@/store';
import {apiPath}  from './http';
import {isBureau} from './auth';

export const updateBadgesBureau = () => isBureau()
    ? axios.get(apiPath('get_admin_badges'))
        .then(response => store.dispatch('badge/setBadges', response.data))
        .catch(() => console.log('Impossible de récupérer les notifications (badges bureau)'))
    : Promise.resolve();

export default {updateBadgesBureau};